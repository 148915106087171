.vendor-page-business-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  /* border: 1px solid red; */
}

.vendor-page-ratings-number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
}

.right-border {
  border-right: 1px solid #ddd;
  padding-right: 13px;
}

.vendor-page-location {
  font-size: .95rem;
  display: flex;
  gap: 5px;
  align-items: center;  
}

.location-details-header {
  text-decoration: underline;
  cursor: pointer;
}

.vendor-page-location > i {
  font-size: 1rem;
}

.vendor-page-ratings-number > i {
  color: rgb(109, 109, 109);
}

.vendor-page-business-top-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Vendor-page-business-tables {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.category-item {
  border-radius: 4px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 300;
  color: rgb(80, 80, 80);
  background: rgb(231, 231, 231);
  font-size: 14px;
}

.vendor-page-business-name {
  font-size: 1.5rem;
}

.vendor-page-reviews {
  text-decoration: underline;
}

.vendor-page-business-ratings-review-location {
  display: flex;
  gap: 10px;
  /* border: 1px solid red; */
}
.vendor-page-ratings {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
.vendor-page-top-left-options {
  display: flex;
  gap: 20px;
}
.vendor-share-btn-wrapper,
.vendor-save-btn-wrapper {
  display: flex;
  gap: 8px;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  transition: .3s;
  cursor: pointer;
}
.vendor-share-btn-wrapper:hover,
.vendor-save-btn-wrapper:hover {
  background-color: rgb(243, 242, 242);
}

.vendor-page-images-container {
  overflow: hidden;
  margin: 40px 0;
  height: 450px;
  display: grid;
  grid-gap: .8em;
  justify-items: stretch;
  grid-template-columns: 1.5fr 1fr 1fr;
  grid-template-rows: minmax(20px, auto);
}

.vendor-page-images-container > div {
  border: 1px solid #ddd;
  width: 100%;
  overflow: hidden;
}

.message-box-details-page {
  padding: 5px;
  padding: 18px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
}

.message-box-details-page > h3 {
  font-size: 1.3rem;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(232, 232, 232);
  font-weight: 300;
}

.message-box-details-page > a {
  width: 100%;
}

.message-box-details-page > p {
  font-weight: 300;
  font-size: 15px;
  color: #4a4a4a;
  padding: 5px 0 !important;
  margin-bottom: 6px !important;
}

.live-video{
  grid-row: 1/3;
  overflow: hidden !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
}

.live-video > iframe {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.simple-images-details-vendor {
  padding: 0 !important;
}

.simple-images-details-vendor > img {
  width: 100%;
  object-fit: contain;
}


.live-icon-info {
  position: absolute;
  color: #fff;
  padding: 5px 13px;
  border-radius: 3px;
  display: flex;
  gap: 4px;
  font-weight: 300;
  font-size: 14px;
  align-items: center;
  left: 20px;
  top: 20px;
  justify-content: space-between;  
  background-color: rgb(206, 3, 3);
}


.top-round-edge {
  border-top-right-radius: 8px;
}

.bottom-round-edge {
  border-bottom-right-radius: 8px;
}

.overview-list {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  margin: 0;
  list-style-type: none;
  gap: 20px;
}

.overview-list > li {
  width: 31.7%;
  display: flex;
  gap: 15px;
  align-items: center;
}

.overview-list > li > div {
  display: flex;
  flex-direction: column;
}

.overview-list > li > div > span {
  font-weight: 500;
  font-size: .9rem;
}

.overview-list > li > div > a {
  font-weight: 300;
  color: #4a4a4a;
  font-size: 15px;
}

.overview-list  > li > svg, .overview-list > li > i{
  font-size: 1.6rem;
  color: var(--logo-color);
}

.vendor-page-top-two,
.vendor-page-bottom-two {
  display: flex;
  justify-content: space-between;
}
/***Categories list ****/
.vendor-page-review-categories-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.vendor-page-category-container {
  max-width: 600px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.vendor-page-progress-bar > progress {
  -webkit-appearance: none;
  height: 4px;
  width: 110px;
}
.vendor-page-progress-bar > progress::-webkit-progress-value {
  background-color: black;
  border-radius: 10px;
}
.vendor-page-progress-bar > progress::-webkit-progress-bar {
  background-color: rgb(233, 227, 227);
  border-radius: 10px;
}
.vendor-page-progress-bar {
  display: flex;
  align-items: center;
  max-width: 150px;
  width: 100%;
  justify-content: space-between;
}
/*4.5 .8 reviews*/
.vendor-page-ratings-review-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
}

.vendor-page-ratings-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60px; /*width of rating container*/
  width: 100%;
}
.vendor-page-ratings-heading i {
  font-size: 1rem;
}
/*a review */
/*Review List*/
.vendor-page-reviews-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* Jaewon */

.Vendor-page-business-info {
  display: flex;  
  justify-content: space-between;
  padding: 20px 0;
  padding-bottom: 0;
  margin-bottom: 30px;
  gap: 30px;
  position: relative;
  top: 0;
}

.Vendor-page-business-info-leftSide {
  width: 70%;
}

.Vendor-page-business-info-rightSide {
  width: 30%;
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: fit-content;
  top: 120px;
}

.Vendor-page-VendorContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}

.Vendor-page-VendorName {
  font-size: 1.5rem
}

.Vendor-page-business-info-leftSide img {
  border-radius: 50%;
  width: 9%;
}

.Vendor-page-business-allLinks {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
}

.Vendor-page-business-allLinks > h3{
  font-size: 1.3rem;
  margin: 0;
  font-weight: 300;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.Vendor-page-business-description > h3{ 
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.Vendor-page-business-description {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.Vendor-page-business-description > p {
  color: #4a4a4a;
  font-weight: 300;
  text-align: justify;
  line-height: 28px;
  font-size: 15px;
  padding-bottom: 15px !important;
}

.Vendor-page-business-description > p:last-child {
  padding: 0 !important;
}

.total-review-count-review-section {
  text-decoration: underline;
}

.inital-message-form {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 5px 10px;
}

.inital-message-form > label {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 1px;
}

.inital-message-form > textarea {
  border: none;
  height: 80px;
  padding-top: 5px;
}

.inital-message-form > textarea:focus {
  outline: none;
}

.send-message-text {
  font-size: 12px !important;
  margin-top: 10px !important;
  text-align: center;
}

