.stories_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  width: 100%;
}


.story_list {
  /* border: 1px solid; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.story_wrapper img {
  width: 90px;
  height: 90px;
  border: 1px solid var(--right-section-font-color);
  padding: 0.2rem;
  border-radius: 50px;
}

.story_wrapper {
  text-align: center;
  cursor: pointer;
}


.horizontal-scroll {
  scroll-behavior: smooth;
  width: 100%;
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
  overflow-x: hidden;
  font-size: 1.3rem;
  gap:25px;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
}



.horizontal-scroll > li {
  border-bottom: 2px solid #f0f0f0;;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 0;
  min-width: fit-content;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  white-space: nowrap;
  transition: .3s;
  color: rgb(86, 86, 86);
  scroll-snap-align: end;
  
}

.horizontal-scroll > li:hover {
  border-color: var(--logo-color);
  color: #000;
  transform: translateY(-5px);
}

.active-category-selected {
  border-color: var(--logo-color) !important;
  color: #000 !important;
  transform: translateY(-5px);
}

.horizontal-scroll > li > svg {
  font-size: 1.9rem;
}

.stories-wrapper button{
  border: 1px solid rgb(59, 55, 55);
  background-color: transparent;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  border-radius: 50%;
  transition: .3s;
}
.prev{
  transform:translateX(-40%) ;
}
.forw{
  transform:translateX(40%) ;
}
.horizontal-scroll > li > span {
  font-size: 12px;
  text-transform: capitalize;
}

.stories-wrapper {
  display: flex;
  padding: 25px 0;
  align-items: center;
  justify-content: space-between;
}

.ghost-button-home{
  background-color: transparent;
  border: 1px solid #ddd;
  transition: .3s;
  padding: 10px 20px;
  color: var(--text-color);
  border-radius: 3px;
}

.ghost-button-home:hover {
  color: var(--text-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.landing-page-popular-businesses-container{
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  
}

.landing-page-item-wrapper {
  display: flex;
  padding: 40px 0;
  flex-direction: column;  
}

.landing-page-popular-businesses {
  /* max-width: 1290px;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  align-items: center; */
  scroll-behavior: smooth;
  display: grid;
  grid-auto-columns: 15.87%;
  grid-auto-flow: column;
  gap: 0.956%;
  overflow-x: scroll;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
}

.landing-page-popular-businesses::-webkit-scrollbar {
  display: none;
}

.landing-page-popular-businesses > * {
  scroll-snap-align: end;
}

.landing-page-item-wrapper > h2 {
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.section-single-container {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}

.section-header-part > p {
  color: rgb(156, 156, 156);
  font-weight: 300;
  font-size: 14px;
}

.section-header-part > h2 {
  margin-bottom: 3px;
  font-size: 1.4rem;
}

.selection-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.selection-stats > a {
  font-weight: 500;
  cursor: pointer;
  transition: .3s;
  color: #000;
}

.selection-stats > a:hover {
  text-decoration: underline !important;
}

.navigation-button {
  display: flex;
  gap: 10px;
  align-items: center;
}

.navigation-button > button {
  border: 1px solid #ddd;
  background-color: transparent;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  border-radius: 50%;
  transition: .3s;
}

.navigation-button > button:hover {
  transform: scale(.95);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.slider-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.slider-wrapper > ul {
  list-style-type: none;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  gap: 15px;
  padding: 0 15px;
}

.slider-wrapper > ul > li {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  filter: grayscale(.3);
  padding: 20px;
}

.slider-wrapper > ul > li > button {
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  transition: .3s;
  padding: 5px 10px;
}

.slider-wrapper > ul > li > button:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-banner-single {
  margin-bottom: 130px;
}

.header-banner-single > span {
  font-size: 13px;
  font-weight: 300;
}

.header-banner-single > h3{
  color: #fff !important;
  font-weight: 400;
  max-width: 380px;
}

.slider-wrapper > ul > li:first-child {
  background: url("https://images.unsplash.com/photo-1509316785289-025f5b846b35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2076&q=80");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slider-wrapper > ul > li:nth-child(2) {
  background: url("https://images.unsplash.com/photo-1512358958014-b651a7ee1773?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slider-wrapper > ul > li:last-child{
  background: url("https://images.unsplash.com/photo-1417816491410-d61e1546e539?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.search-box-slider-landing-page {
  display: flex;
  align-items: center;
  background: url("../../../util/images/banner.jpg");
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 0px -400px;
  background-size: 1500px 1300px;
}

.search-box-slider-item-wrapper-overlay {
  padding: 50px 0;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(30,30,30,0.75);
}

.search-box-slider-item-wrapper {
  color: #fff !important;
}

.search-box-slider-item-wrapper > h1, .center-text-like-gg > p > h1, .center-text-like-gg > h1{
  color: #fff !important;
  font-weight: 400;
}

.center-text-like-gg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center-text-like-gg > h1 {
  margin-right: 100px !important; 
}

.center-text-like-gg > p {
  margin-left: 100px !important;
}

.search-box-slider-item-wrapper > p {
  font-size: 24px;
  color: #888;
  font-weight: 300;
  line-height: 20px;
  display: block;
  letter-spacing: -0.5px;
}

.main-search-box-header {
  display: flex;
  border-radius: 4px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255);
}

.single-field-holder-landing-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  border-left: 1px solid #ddd;
}

.search-box-size {
  width: 35%;
}

.single-field-holder-landing-page > input, .putting-two-in-one > input, .single-field-holder-landing-page > select {
  padding: 20px 15px;
  width: 100%;
  position: relative;
  background-color: #f8f8f833;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
  border: none;
  font-size: 15px;
  font-weight: 400;
  height: 25px;
}

.putting-two-in-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.putting-two-in-one > i {
  color: #b0b0b0;
  font-size: 17px;
  padding-right: 10px;
}

.single-field-holder-landing-page:nth-child(3){
  border-right: none !important;
}

.left-radius {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.search-button-header {
  margin-left: 5px;
  background-color: var(--logo-color);
  color: #fff;
  border: none;
  margin: 7px;
  border-radius: 5px;
  width: 7%;
}

input:focus {
  outline: none;
}

.single-field-holder-landing-page > label {
  background: rgba(255, 255, 255, 0.07);
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  line-height: 22px;
  top: -45px;
  cursor: default;
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.line-1{
  position: relative;
  width: 10em;
  border-right: 2px solid rgba(255,255,255,.75);
  text-align: center;
  white-space: nowrap;
  font-size: 25px;
  margin-top: 20px;
  overflow: hidden;
}

/* Animation */
.anim-typewriter{
animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
from{width: 0;}
to{width: 10em;}
}
@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}


.small-text-flow-form-landing {
  background: #f0f0f0;;
  color: #000;
  width: 13%;
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding: 5px 10px;
  line-height: 22px;
  justify-content: space-between;
  cursor: default;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.small-text-flow-form-landing > i {
  font-size: 20px;
}

.keyword-strong {
  color: var(--logo-color);
  font-weight: 400;
}

.select-field-categries-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: rgb(122, 122, 122);
  font-size: 15px;
  cursor: pointer;
}

.select-field-categries-container > span {
  gap: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
}

.items-container {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  top: 60px;
  max-height: 350px;
  overflow-y: scroll;
  left: 0;
  z-index: 400;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.category-list-box {
  display: flex;
  list-style-type: none;
  padding: none;
  margin: 0;
  flex-wrap: wrap;
}


.category-list-box > li {
  display: flex;
  width: 33.3%;
  flex-direction: column;
  padding: 15px 10px;
  gap: 7px;
  align-items: center;
  border-right: 1px solid rgb(237, 237, 237);
  border-bottom: 1px solid rgb(237, 237, 237);
  transition: .3s;
}

.category-list-box > li:nth-child(3n-6) {
  border-right: none;
}

.category-list-box > li > p {
  font-weight: 300;
  text-align: center;
  font-size: 12px;
}

.category-list-box > li > i {
  font-size: 1rem;
}

.category-list-box > li:hover {
  background-color: #efefef;
}

.show-dropdown {
  animation: showDropdownCategory .4s forwards;
}

.hide-dropdown {
  animation: hideDropdownCategory .4s forwards;
}

@keyframes showDropdownCategory {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 350px;
  }
}

@keyframes hideDropdownCategory {
  100% {
    max-height: 0px;
  }
}

.active-categorie {
  background-color: #1071e133 !important;
  color: var(--logo-color);
}

.absolute-value-behind-search {
  color: rgba(18, 18, 18, 0.168);
  position: absolute;
  z-index: 1;
  left: 420px;
  font-size: 20px;
  font-weight: 300;
}