.dropbox-container {
  text-align: center;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  width: 60%;
  margin: auto;
}

.file-list {
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
}

.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}

.dropzone-text-center{
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .file-upload-container {
  position: relative;
  margin: 25px 0 15px;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.input-label {
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
}

.drag-drop-text {
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
} */

.upload-file-button {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #3498db;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 45%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;
}

.upload-file-button:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #3498db;
  z-index: -1;
  transition: width 250ms ease-in-out;
}

.upload-icon {
  font-size: 24px;
  margin-right: 5px;
  border-right: 2px solid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .upload-file-button {
    width: 70%;
  }
}
@media only screen and (max-width: 350px) {
  .upload-file-button {
    width: 100%;
  }
}

.upload-file-button:hover {
  color: #fff;
  outline: 0;
  background: transparent;
}

.upload-file-button:hover:after {
  width: 110%;
}

.upload-file-button:focus {
  outline: 0;
  background: transparent;
}

.upload-file-button:disabled {
  opacity: 0.4;
  filter: grayscale(100%);
  pointer-events: none;
} 

/* .form-field {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.form-field:focus {
  outline: none;
} */