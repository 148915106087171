.form-wrapper-container {
  /* border: 1px solid red; */
  width: 1200px;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrapper {
  display: flex;
}

.name-input-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid red; */
}
.profile_img_editable {
  border-radius: 50px;
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.form-group {
  width: 49%;
}

.other {
  position: relative;
  left: 12.5rem;
  /* border: 1px solid red; */
}
.image-wrapper {
  display: flex;
}
.image-wrapper > .mb-3 > .form-control {
  margin-top: 2rem;
  padding-left: 20%;
}
.image-wrapper > .mb-3 {
  margin-left: 1rem !important;
  width: 100%;
  padding: 2rem;
  border: 1px dotted #000 !important;
}
/***Button save***/

.save-btn {
  position: relative;
  left: 40%;
  padding: 1rem 4rem;
}

.list-selection-settings {
  display: flex;
  margin-top: 30px;
  list-style-type: none;
}

.list-selection-settings > li {
  margin-right: 20px;
  border-radius: 3px;
  padding: 3px 10px;
  border: 1px solid rgb(234, 234, 234);
}

.list-selection-settings > li > a {
  text-decoration: none;
  font-weight: 400;
  color: rgb(168, 168, 168);
  cursor: pointer;
  transition: .3s;
}

.list-selection-settings > li > a:hover {
  color: #007bff !important;
}

.box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.profile-box {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  width: 50%;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.Verified-Status {
  border-color: #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 15px 0;
}

.border-bottom {
  border-bottom: 1px solid;
}

.Verified-Status > svg {
  color: rgb(25, 178, 25);
  font-size: 1.5rem;
}

.Verified-Status > p {
  color: #007bff;
  font-weight: 300;
}

.profile-box > h3 {
  font-size: 1.3rem;
  color: rgb(73, 73, 73);
}

.profile-box > h2, .header-profile-box > h2{ 
  font-size: 1rem;
  margin-bottom: 20px;
}

.header-profile-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.header-profile-box > p {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 300;
}

.profile-box > p {
  color: rgb(172, 172, 172);
  font-size: .9rem;
}

.profile-icon > svg {
  color: #007bff;
  font-size: 3rem;
  margin-bottom: 20px;
}

.wd-90 {
  width: 90%;
}

.wd-100 {
  width: 100%;
}

.download-btn-holder {
  display: flex;
  padding: 30px 0;
  margin-bottom: 0;
  padding-bottom: 0;
  gap: 20px;
}

.download-Buttons {
  border-radius: 5px;
  padding: 10px;
  background-color: #007bff;
  color: #fff !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: .3s;
  gap: 10px;
}

.download-Buttons:hover {
  background-color: #035fc2;
}

.download-Buttons > svg {
  font-size: 2rem;
}

.download-Buttons > span {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


.download-Buttons > span > p {
  font-size: .5rem;
}

.download-Buttons > span > h5 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

.user-information-setting-panel {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
}

.user-information-setting-panel > li {
  width: 33.33%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.user-information-setting-panel > li > span {
  color: #9e9e9e;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}


.user-information-setting-panel > li > h5 {
  font-weight: 450;
  font-size: 1.1rem;
}

.active-button-setting {
  color: #007bff !important;
  border-color: #007bff !important;
}

.hour-text {
  display: inline;
  padding: 10rem;
}

.hour-input {
  width: 25%;
}