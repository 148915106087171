.loading-Screen-Container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.loading-Screen-Container > p {
  color: var(--logo-color);
  font-weight: 300;
  font-size: 14px;
}

#loading {
  display: inline-block;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--logo-color);
  height: 60px;
  border: 3px solid rgba(55, 55, 55, 0.3);
  border-radius: 50%;
  border-top-color: var(--logo-color);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}