.review-box-container {
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 0;
  border: 1px solid;
}

.select-field-container {
  display: flex;
  flex-direction: column;
}

.select-field-review {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
  padding: 15px 10px;
}

.select-field-review::after {
  padding-right: 10px !important;
}

.review-box {
  max-width: 780px;
  border-radius: 5px !important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
  width: 100%;
  background-color: #fff;
  padding: 25px;
}

.review-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-form-header-content > h3 {
  font-size: 1.5rem;
  margin-bottom: 3px;
}

.review-form-header-content > p {
  font-size: 15px;
  font-weight: 300;
  color: rgb(91, 91, 91);
}

.review-form-header > button {
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
}

.review-box > form {
  padding-top: 20px;
}

.review-form-lable {
  font-size: 14px;
  color: rgb(91, 91, 91);
}