.container-auth {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.col-information-wrapper {
  width: 40%;
  height: 100%; 
  background-image: url('https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.background-layer-wrapper{
  background-color: #0c68f1ab;
  width: 100%;
  padding: 40px;
  height: 100%;
}

.background-layer-info {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 80%;
}

.background-layer-info > h2 {
  color: #F7F5FB;
  font-size: 2.50rem !important;
  font-weight: 400;
  margin-bottom: 40px;
}

.background-layer-info > p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px !important;
}

.background-layer-icons {
  position: fixed;
  bottom: 1.5rem;
  left: 15%;
}

.background-layer-icons i {
  padding-right: 1rem;
  font-size: 20px;
  color: #F7F5FB;
}

.col-form-wrapper {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.title-auth {
  font-size: 1.7rem;
  margin-bottom: .8rem;
}

.divider-line {
  display: flex;
  margin-top: 1.5rem!important;
  margin-bottom: 1.5rem!important; 
  align-items: center;
}

.divider-line {
  color: #6c757d!important;
}

.text-muted {
  --bs-text-opacity: 1;
  font-weight: 300;
  letter-spacing: .01rem;
}

.big-line {
  flex-grow: 1!important;
}

.small-line{
  flex: 0 0 auto;
  width: 8.33333%;
}

.mx-3 {
  margin-right: 1rem!important;
  margin-left: 1rem!important;
}

.registration-account-type-list {
  border: 1px solid;
  list-style-type: none;
}

.social-login-button {
  display: flex;
  gap: 10px;
}

.social-login-button > a {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
}

.social-login-button > a:first-child {
  background-color: #DB4437;
}

.social-login-button > a:nth-child(2){
  background-color: #0d6efd;
}

.form-control:not(.form-control-sm) {
  padding: 0.81rem 0.96rem;
  height: inherit;
}

.form-control {
  border-color: #dae1e3;
  font-size: 16px;
  color: #656565;
}

.form-control {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem; 
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  height: auto !important;
  font-family: 'Ubuntu', sans-serif !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  appearance: none !important;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
}

.mt-4 {
  margin-top: 1.5rem!important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.max-width-auth-pages {
  max-width: 500px;
  width: 100%;
}

.card-selection {
  display: flex;
  padding: 20px;
  padding-left: 10px;
  border: 1px solid #ced4da;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: .3s;
}

.card-selection:hover {
  transform: scale(.95);
}

.card-selection > span > i {
  margin-right: 10px;
}

.light-under-field-text { 
  margin-top: -10px;
  margin-bottom: 10px;
}

.ghost-button {
  border: 1px solid #fff;
  padding: 13px 25px;
  font-size: 18px;
  border-radius: 4px;
  color: #fff !important;
  cursor: pointer;
  margin-top: 15px !important;
}

.logo-icon {
  color: #fff;
  letter-spacing: -2px;
}

.item-footer-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-item-code-holder {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.invalid-error-message {
  font-weight: 300;
  font-size: 14px;
  color: var(--danger) !important;
}

.text-button {
  border: none !important;
  background-color: transparent;
  color: #0d6efd;
  transition: .3s;
}

.text-button:hover {
  text-decoration: underline;
  color: #0b4bab;
}

.text-button:disabled {
  cursor: not-allowed;
  color: #656565;
  text-decoration: none !important;
}