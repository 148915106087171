.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* Chrome */
}
  
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.arrow-container {
	cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  font-size: 40px;
}