* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dropdown_wrapper {
  overflow: hidden;
  position: absolute;
  right: 1rem;
  margin-top: 18px;
  border-radius: 3px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropdown_option {
  width: 200px;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 1px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  transition: .2s;
  gap: 10px;
}


.dropdown_option:hover{
  background-color: #007bff;
  color: #ffffff !important;
}

.profile_info_wrapper {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.profile_info_wrapper i {
  font-size: 1.5rem;
  color: blue;
}

.name_and_email_wrapper > .username {
  font-weight: bold;
}

.name_and_email_wrapper > .email {
  color: #a8a7b8;
}

.settings_wrapper {
  display: flex;
  align-items: center;
}

.settings_wrapper i {
  font-size: 1.5rem;
  color: blue;
}

.logout_wrapper {
  display: flex;
  align-items: center;
}

.logout_wrapper i {
  font-size: 1.5rem;
  color: blue;
}

.last {
  border-bottom: none;
}
