.business-card {
  overflow: hidden;
  min-width: 220px;
	padding: 0 1rem;
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 160px;
}

.business-card img {
  width: 100%;
  display: block;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.business-card .overlay {
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, rgba(0, 0, 0, 0.75)),
      to(transparent));
  background: linear-gradient(180deg, rgba(228, 228, 228, 0.75), transparent);
  padding: 20px;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.business-card .overlay .title {
	color: Black;
  font-size: 22px;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}


.business-card:hover img {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

.business-card:hover .overlay {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.business-card:hover .views {
  margin-top: 10px;
  opacity: 1;
}

.business-card:hover .list_toggle,
.business-card:hover .company,
.business-card:hover .title {
  opacity: 1;
}

.list_toggle {
  --size: 32px;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  width: var(--size);
  height: var(--size);
  font-size: var(--size) / 2;
  border-radius: var(--size);
  overflow: hidden;
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0;
}

.list_toggle:hover {
  border: 2px solid var(--white);
}

.list_toggle:hover div {
  top: -32px;
}

.list_toggle[data-toggled="true"] {
  background: var(--red);
  border: 2px solid var(--red);
}

.list_toggle[data-toggled="true"] div {
  top: -32px;
}

.list_toggle div {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px * 2;
  width: 32px;
  -webkit-transition: 0.125s ease;
  transition: 0.125s ease;
}

.list_toggle div i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}

