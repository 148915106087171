.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 500;
  background-color: #fff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
}


.header-wrapper-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}



.logo_wrapper {
  display: flex;
  align-items: center;
}

.logo_wrapper > h2 {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: var(--logo-color);
}


.logo_wrapper > svg {
  font-size: 2.5rem;
}

.header-nav {
  display: flex;
  margin: 0 !important;
  display: flex;
  align-items: start;
  list-style-type: none;
}


.connection-log-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-nav > li > a {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 30px 20px;
  font-size: 1rem;
  color: var(--dark-menu-color);
  font-weight: 300;
  transition: 0.5s;
}

.header-nav > li > a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  background: var(--logo-color);
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
}

.header-nav > li > a:hover {
  color: #000;
}
.header-nav > li > a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.right_section_wrapper > button {
  border: none;
  background-color: var(--logo-color);
  color: #fff;
  transition: .3s;
  border: 2px solid var(--logo-color);
  border-radius: 3px;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  font-size: 14px;
}

.right_section_wrapper > button:hover {
  background-color: transparent;
  color: var(--logo-color);
 }



.right_section_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_section_wrapper > div {
  font-size: 1.5rem;
  color: var(--right-section-font-color);
}

.user_profile_wrapper {
  display: flex;
  border-left: 1px solid #ddd;
  padding-left: 20px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.circle-button-header {
  color: var(--text-color);
  transition: .3s;
  padding: 6px 12px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.circle-button-header:hover {
  color: #000;
}

.login-button-header {
  transition: .3s;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  color: rgb(23, 23, 23);
  border: 1px solid rgb(191, 191, 191);
  cursor: pointer;
  position: relative;
  display: flex;
}

.profile-Button-wrapper {
  padding: 8px 15px;
  display: flex;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  background-color: #ddd;
  color: #000;
}


.login-button-header > span {
  font-weight: 500;
  font-size: 14px;
}

.login-button-noti-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;

  padding-right: 20px;
  gap: 10px;
  color: rgb(126, 126, 126);
  cursor: pointer;
  transition: .3s;
  position: relative;
}

.login-button-noti-btn:hover {
  color: #000 !important;
}

.login-button-noti-btn > i {
  cursor: pointer;
  font-size: 1.4rem;
  border: 1px solid #fff;
  margin: 0;
  padding: 0;
  padding-left: 5px;
}

.login-button-noti-btn > svg {
  padding: 0;
  margin: 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hover-notification-box {
  position: absolute;
  background-color: #fff;
  transition: .3s;
  top: 120%;
  right: -30px;
  display: none;
  border-radius: 3px;
  max-width: 400px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
}

.showProfileBox {
  display: block;
}

.account-box {
  width: 250px;
  margin-right: 15px;
  z-index: 99999;
}

.account-box > ul {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  margin: 0;
  padding: 5px 0;
}

.account-box > ul > li {
  font-size: .9rem;
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;
  transition: .3s;
}


.account-box > ul > li:hover {
  background-color: rgb(233, 233, 233);
}


.account-box > ul > li:last-child{
  border-top: 1px solid #ddd;
}

.notification-box-front-end{
  display: flex;
  width: 400px !important;
  margin: 10px 0;
  z-index: 100;
  flex-direction: column;
}

.notification-box-front-end > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.notification-box-front-end > div > span {
  font-size: 15px;
  color: #000;
}

.notification-box-front-end > div > a {
  font-size: 15px;
  text-decoration: underline;
}

.notification-box-front-end > ul {
  list-style-type: none;
  margin: 0;
}

.notification-box-front-end > ul > li { 
  display: flex;
  padding: 15px 15px;
}

.notification-box-front-end > ul > li > div {
  display: flex;
  flex-direction: column;
}

.notification-box-front-end > ul > li > div > h5 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.notification-box-front-end > ul > li > div > p {
  font-size: .85rem;
}

.notification-box-front-end> ul > li > div > span {
  font-size: .8rem;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.notification-box-front-end > ul > li:hover {
  background-color: rgb(233, 233, 233);
} 

.signup-link {
  color: white;
  text-decoration: none;
}

.like-button-header {
  color: rgb(126, 126, 126);
  font-size: 1.5rem;
  transition: .3s;
  padding-right: 15px;
}

.like-button-header:hover {
  transform: translateY(-3px);
}

.logo-tilt-fix {
  border: 1px ;
  transform: rotate(7deg);
}

.search-bar {
  padding: 10px 10px;
  background-color: #eee;
  display: flex;
  max-width: 500px;
  width: 100%;
  transition: .3s;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 24px;
  height: 40px;
  align-items: center;
}

.search-bar:hover {
  border: 1px solid rgb(201, 201, 201);
}

.search-bar > input {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 15px;
  background: none;
  border: none;
  color: #111;
  width: 100%;
  -webkit-appearance: textfield;
  outline-offset: -2px;
}


.search-bar > i {
  margin-right: 15px;
  margin-left: 10px;
}

.search-bar > input:focus {
  outline: none;
}

.search-bar > input:focus .search-bar {
  
  background-color: #fff !important;
}

.search-bar-header-container {
  display: flex;
  max-width: 600px;
  width: 100%;
  margin: 20px 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(227, 227, 227);;
}


.search-bar-header-container > input {
  -webkit-appearance: none;
  box-shadow: none;
  padding-bottom: 2px;
  background: none;
  border: none;
  color: #111;
  width: 100%;
  background-color: rgb(242, 242, 242);
  font-weight: 300;
  font-size: 14px;
  padding: 10px 20px;
  padding-left: 15px;
  transition: .3s;
  padding-right: 30px;
}

.search-bar-header-container > select {
  border: none;
  border-right: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  background-color: rgb(242, 242, 242);
}

.search-bar-header-container > select::after {
  position: absolute;
  content: "";
  top: 14px;
  right: 20px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.search-bar-header-container > input:focus, .search-bar-header-container > select:focus {
  outline: none;
}

.search-bar-header-container > input:focus {
  background-color: #fff !important;
}

.search-icons {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.logo-overflow {
  /* border-radius: 50%;
  height: 150px;
  width: 150px;
  object-fit: contain;
  position: absolute;
  top: -25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 5px;
  padding: 10px;
  background-color: #fff !important; */
}

.header-menu-list {
  display: flex;
  list-style-type: none;
  border-left: 1px solid #ddd;
  height: 100%;
  padding: 25px 0;
  gap: 20px;
  margin: 0;
  margin-left: 30px;
  padding-left: 20px;
}

.right-side-nav > ul{
  border: none;
  border-right: 1px solid #ddd;
  margin-left: 0px;
  padding-left: 0px;
  margin-right: 20px;
  padding-right: 25px;
  align-items: center;
}

.right-side-nav > ul > li {
  margin: 0 !important;
  padding: 0 !important;
  padding-bottom: 5px !important;
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header-menu-list > li {
  padding: 5px 10px;
  border-radius: 5px;
}

.header-menu-list > li > a {
  color: rgb(54, 54, 54);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 350;
  transition: .3s;
  line-height: 27px;
}

.header-menu-list > li > a:hover {
  color: var(--logo-color);
}