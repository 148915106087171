.search-box-container {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
  max-width: 350px;
  width: 100%;
  z-index: 8999;
  padding: 20px 0px;
  padding-bottom: 0;
}

.close-side-bar {
  width: 5%;
  display: flex;
  align-items: center;
  font-size: 3rem;
}

.search-box-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 5px;
  margin: 0 10px; 
  margin-bottom: 10px;
}

.search-box-container-wrapper > span {
  font-size: 2rem;
}

.show-side-search {
  animation: showSide .3s forwards ;
}

.hide-side-search {
  animation: hideSide .3s forwards ;
}

.search-field {
  border: 1px solid;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 3px;
  padding: 2px 10px;
}

.search-field > input {
  border: none;
  padding: 8px 5px;
  margin: 0 5px;
  width: 90%;
  font-weight: 400;
}

.search-field > input::placeholder {
  font-weight: 400;
}

.search-field > svg {
  font-size: 1.2rem;
  color: rgb(99, 99, 99);
  width: fit-content;
}

.search-field > svg:last-child {
  font-size: 1.5rem;
  opacity: 0;
  transition: .3s;
  cursor: pointer;
}

.search-field > svg:last-child:hover {
  color: #000;
}

.show-icon {
  opacity: 1 !important;
}

.search-field > input:focus {
  outline: none;
}

@keyframes showSide {
  0% {
      transform: translateX(-350px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hideSide {
  0% {
      transform: translateX(0px);
  }
  100% {
    transform: translateX(-350px);
  }
}

.search-results {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.result-section {
  width: 100%;
}

.result-section > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.result-section > ul > li {
  border-top: 1px solid rgb(231, 231, 231);
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: safe;
  border-left: 4px solid #fff;
  gap: 15px;
  align-items: baseline;
  transition: .3s;
}

.result-section > ul > li:last-child {
  border-bottom: 1px solid rgb(231, 231, 231);
  
}

.result-section > ul > li > span {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.result-section > ul > li > span > img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.result-section > ul > li:hover {
  background-color: rgb(237, 237, 237);
  border-left: 4px solid var(--logo-color);
}

.result-section > ul > li > p {
  font-size: .9rem;
}

.result-section > ul > li > p > span {
  font-weight: 300;
  color: rgb(169, 169, 169);
}

.collapse-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px;
  background-color: rgb(236, 236, 236);
  margin-bottom: 0;
}

.collapse-container > svg {
  font-size: 2rem;
  border: 1px solid rgb(169, 169, 169);
  padding: 5px;
  border-radius: 4px;
  color: rgb(137, 137, 137);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse-container > h5 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1rem;
}